@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.App {
  text-align: center;
  display: flex;
  flex-grow: 1;
  height: 100vh;
  width: 100vw;
  font-family: "Poppins", sans-serif;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Custom */

h1 {
    font-size: 60px;
}

ul.nav {
    list-style: none;
    list-style-image: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin: 0px auto;
    padding: 0px;
}

ul.nav > li {
    
    padding: 10px;
    flex-grow: 1;
}

ul.nav > li:has(> a.enabled-link):hover {
    border: 4px solid rgb(69, 47, 107, 0.90);
    background-color: rgb(17, 5, 37);
}

/* Content */

.appBar {
    background-color: black;
    height: 5vh;
    
    
    color: white;
    padding: 2px 10px;
    align-content: center;
    font-size: 20px;

}

.appBar > div {
    width: 70%;
    display: flex;
    flex-direction: row;
    margin: 0px auto;
    /*padding: 0px 60px;*/
}

.appBar > div > img {
    width: 40px; height: 40px;
    margin: auto 10px;
    cursor: pointer;
}

    .appBar > div > p {
        margin: 20px 10px;
        align-self: center;
        cursor: pointer;
    }

.appBar > div > ul {
    margin: 0px 0px 0px auto;
    color: white;
}

.appBar > div > ul > li > a {
    text-decoration: none;
}

.appBar ul li:hover {

}

.disabled-link {
    color: grey;
}

.disabled-link:hover {
    border: 0px;
}

.grid {
    background-image: url('../../public/bg_grid_single.png');
    background-color: white;
    background-repeat: repeat;
    background-size: 40px 40px;
    width: 100vw;
    height: 100%;
}

.grid-color {
    background-image: linear-gradient(rgba(27, 12, 54,0.6),rgb(27, 12, 54, 0.82), rgb(27, 12, 54, 0.9), rgb(27, 12, 54, 0.95),rgb(27, 12, 54));
    background-blend-mode: difference;
    width: 100vw;
    height: 100%;
}

body-content {
    display: flex;
    flex-grow: 1;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    flex-direction: column;
}

.intro {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40vh;
    width: 100%;
    align-content: center;
    justify-content: center;
    width: 70%;
    margin: 20px auto;
    border-radius: 30px;
}

.intro-bg {
    background-image: linear-gradient(rgb(214, 214, 214, 0.50), rgb(47, 28, 80, 0.8))
}

.intro-content {
    display: flex;
    flex-direction: row;
    width: 70%;
    justify-content: space-evenly;
    align-items: center;
}
/* Have intro card stay put*/

.content {
    width: 70%;
    margin: 40px auto 10px auto;
    color: white;
}

.content-select {
    width: 100%;
    background-color: black;
    height: 70px;
    border-radius: 10px 10px 0px 0px;
    padding: 10px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.content-select > ul {
    border: 2px solid rgb(62, 40, 101);
    width: 80%;
}

.content-select > ul > li {
    border: 4px solid rgb(42, 22, 77, 0.90);
}

.content-select > ul > li:hover {

}

.content-box > img {
    width: 200px;
    height: 200px;
}

.content-box {
    margin: 20px;
    display: flex;
    flex-direction: row;
    padding: 20px;
    justify-content: space-evenly;
}

.content-box > img {
    border-radius: 10px;
    margin: auto 20px;
}

#projects > div:nth-child(even) {
    flex-direction: row-reverse;
}

.content-text {
    color: white;
    padding: 20px;
    justify-content: center;
    text-align: center;
    background-color: rgb(9, 1, 23);
    border-radius: 10px;
    width: 60%;
    align-content: center
}

.content-text > p {
    padding: 0px 30px;
}



.content-button-container {
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: space-around;
    margin: 10px auto;
}

.content-button {
    width: 40%;
    color: white;
    background-color: rgb(69, 47, 107, 0.90);
    border-radius: 5px;
    padding: 5px;
}

.content-button > a {
    text-decoration: none;
    color: white;
}

.content-button.disabled {
    background-color: rgb(16, 6, 35, 0.90);
}

    .content-button.disabled > a {
        color: grey;
        cursor: not-allowed;
    }

.remaining {
    background-color: rgb(16, 6, 35, 0.90);
    border-radius: 0px 0px 10px 10px;
    justify-content: center;
    align-content: center;
}

.filler {
    height: 100vh;
}

.footer {
    width: 100%;
    background-color: rgb(8, 2, 21, 0.90);
}

.footer > .content {
    margin-bottom: 0px;
    background-color: black;
}

.footer > .content-text {
    margin-bottom: 0px;
    background-color: rgb(16, 6, 35, 0.90);
}