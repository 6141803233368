* {
    color: white;
}


.intro-content > div {
    justify-content: center;
    align-items: center;
    margin: 0px 10px;
}

.intro-medium {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
}

.intro-medium > .intro-content {
    justify-content: flex-start;
}

.intro-medium > .intro-content > div {
    width: 33%;
}

.intro-medium-bg {
    background-image: linear-gradient(rgb(27, 12, 54), rgb(27, 12, 54,0.8), rgb(27, 12, 54))
}

.content-image.proj > img {
    width: 400px; height: 400px;
}
.content-button-container.proj {
    width: 100%;

}
.content-button-container.proj > .content-button {
    flex-grow: 1;
    margin: 10px;
}

.content-text > p > ul {
    /*display: flex;
    justify-content: flex-start;*/
    text-align: left;
}

.content-text > p > ul > li {
    padding: 5px;
}

.content-text > p > a {
    text-decoration:underline;
}
